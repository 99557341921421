import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Judge from "./judge"

import "swiper/swiper.scss"

const Judges = () => {
  const colors = [
    "bg-sa-yellow",
    "bg-blue-light",
    "bg-green",
    "bg-red",
    "bg-blue",
  ]

  const no_of_colors = colors.length
  const judges_query = useStaticQuery(graphql`
    query judgesQuery {
      allContentfulJudges {
        edges {
          node {
            fullname
            bio {
              bio
            }
            image {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }
  `)

  const showJudges = judges_query.allContentfulJudges.edges.map(
    (judge, index) => (
      // <SwiperSlide>
      <Judge
        key={index}
        name={judge.node.fullname}
        bio={judge.node.bio.bio}
        image={judge.node.image}
        background={colors[index % no_of_colors]}
      />
      // </SwiperSlide>
    )
  )

  const judgesMobile = showJudges.map((judge, index) => (
    <SwiperSlide key={index}>{judge}</SwiperSlide>
  ))
  return (
    <section className=" max-w-[900px] md:pl-10 pl-0 lg:ml-64  md:pr-7 pr-0 my-28">
      <div className="md:block hidden">{showJudges}</div>
      <div className="md:hidden block">
        <Swiper spaceBetween={0} slidesPerView={1.2}>
          {judgesMobile}
        </Swiper>
      </div>
    </section>
  )
}

Judges.propTypes = {}

export default Judges
