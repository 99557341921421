import React from "react"
import Layout from "../components/layout"
import JudgingPanelDescription from "../components/pages/judging-panel/description"
import Judges from "../components/pages/judging-panel/judges"
import PageHeader from "../components/utils/page-header"

const JudgingPanel = ({ location }) => {
  return (
    <Layout title="Judging Panel" url={location.pathname}>
      <PageHeader
        topText="Judged by"
        bottomText="experts"
        title="Judging Panel"
      />
      <JudgingPanelDescription />
      <Judges />
    </Layout>
  )
}

export default JudgingPanel
