import React from "react"
import Container from "../../utils/container"

const JudgingPanelDescription = () => {
  return (
    <Container>
      <p>
        The #ACB20 is selected based on the informed judgement of our panel of
        seasoned professionals who have graciously committed to helping us
        improve the quality of the assessment and provide useful feedback for
        the overall discourse of brand engagement.{" "}
      </p>
    </Container>
  )
}

JudgingPanelDescription.propTypes = {}

export default JudgingPanelDescription
